import React, { useEffect, useState } from "react";

import { useLazyQuery } from "@apollo/client";
// import { Link } from "gatsby";
import { Helmet } from "react-helmet";

import PromoBlock from "@/components/common/promoBlock";
import { CarouselSeoUrlPrefixEnum } from "@/globalTypes";
import { convertToShortDate } from "@/utils/dateUtils";
import { cleanupFromDangerousTags } from "@/utils/stringUtils";
import Breadcrumbs from "@components/common/Breadcrumbs";
import { BreadcrumbUrlPrefixEnum } from "@components/common/Breadcrumbs/types";
// import ExpertStatus from "@components/common/expertStatus";
import { Icon, IconSizeEnum, IconTypeEnum } from "@components/common/icon";
// import Rating from "@components/common/rating";
import SeoHeaders from "@components/common/seoHeaders";

import CarouselLinksBlock from "../carouselLinksBlock";
import Constructor from "../constructor";
import Persons from "../persons";
import { getReadDurationMinByText } from "../utils";

import { MIN_UPDATE_DIFFERENCE_MS } from "./constants";
import {
  getExpertState as getExpertStateType,
  getExpertStateVariables,
} from "./graphql/__generated__/getExpertState";
import { GET_EXPERT_STATE } from "./graphql/GET_EXPERT_STATE";
import { ArticleProps } from "./types";

import "../styles.scss";
import "./styles.scss";

const Article = ({ pageContext }: ArticleProps) => {
  const {
    seoId,
    title = "Статья на Lunaro",
    ogTitle,
    description = "Статья на Lunaro",
    text,
    textMiddle,
    textBottom,
    header,
    published,
    updated,
    breadcrumbDisplayName,
    breadcrumbs,
    url,
    author,
    expert,
    autolinkedArticleLinks,
    zone,
    commonTextTopBottomZone,
    commonTextMiddleBottomZone,
    commonBottomZone,
    expertsInitial,
  } = pageContext;

  const publishDateTimestamp = (new Date(published)).getTime();
  const updatedTimestamp = (new Date(updated)).getTime();
  const publishDate = convertToShortDate(publishDateTimestamp);
  const updateDate = (updatedTimestamp - publishDateTimestamp >= MIN_UPDATE_DIFFERENCE_MS)
    ? convertToShortDate((new Date(updated)).getTime()) : null;
  const minToRead = getReadDurationMinByText(`${text ?? ""} ${textMiddle ?? ""} ${textBottom ?? ""}`);

  const [expertStatus, setExpertStatus] = useState(expert?.state);

  const [getExpertState, {
    data,
    error,
    loading,
  }] = useLazyQuery<getExpertStateType, getExpertStateVariables>(GET_EXPERT_STATE, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (expert) {
      getExpertState({ variables: { id: expert.id } });
    }
  }, [expert, getExpertState]);

  useEffect(() => {
    if (data && !error && !loading) {
      setExpertStatus(data.getExpert.state);
    }
  }, [data, error, loading]);

  return (
    <>
      <SeoHeaders
        title={title}
        ogTitle={ogTitle}
        description={description}
        url={url}
        imageUrl={text?.match(/src\s*=\s*"(.+?)"/)?.[1]
          || textMiddle?.match(/src\s*=\s*"(.+?)"/)?.[1]
          || textBottom?.match(/src\s*=\s*"(.+?)"/)?.[1]}
      />
      <Helmet>
        <meta itemProp="description" content={description} />
      </Helmet>
      <PromoBlock />
      <div className="bg--white">
        <div
          className="page-width"
          itemScope
          itemType="https://schema.org/Article"
        >
          <Breadcrumbs
            seoId={seoId}
            url={url}
            breadcrumbDisplayName={breadcrumbDisplayName}
            breadcrumbs={breadcrumbs}
            urlPrefix={BreadcrumbUrlPrefixEnum.Article}
          />
          <div className="seo-article">
            <h1 itemProp="headline" className="seo-article__headline">{header || ""}</h1>
            <div className="seo-article__data">
              <div itemProp="datePublished" className="seo-article__data-item">{publishDate}</div>
              {updateDate && (
              <div className="seo-article__data-item">
                Обновлено
                {" "}
                {updateDate}
              </div>
              )}
              <div className="seo-article__data-item">
                <Icon type={IconTypeEnum.Time} size={IconSizeEnum.Size20} />
                <div>
                  {minToRead}
                  {" "}
                  мин на&nbsp;чтение
                </div>
              </div>
            </div>
            <div
              itemProp="articleBody"
              className="seo-typography"
              dangerouslySetInnerHTML={{
                __html: cleanupFromDangerousTags(text || ""),
              }}
            />
          </div>
          <Constructor zone={commonTextTopBottomZone} expertsInitial={expertsInitial} />
          <div
            itemProp="articleBody"
            className="seo-typography"
            dangerouslySetInnerHTML={{
              __html: cleanupFromDangerousTags(textMiddle || ""),
            }}
          />
          <Constructor zone={commonTextMiddleBottomZone} expertsInitial={expertsInitial} />
          <div
            itemProp="articleBody"
            className="seo-typography"
            dangerouslySetInnerHTML={{
              __html: cleanupFromDangerousTags(textBottom || ""),
            }}
          />
          <Constructor zone={zone} expertsInitial={expertsInitial} />
          <Persons author={author} expert={expert} />
          {autolinkedArticleLinks?.length > 0 && (
            <div className="seo-article__carousel-block-container">
              <h2>Читайте также</h2>
              <CarouselLinksBlock
                autolinkedArticleLinks={autolinkedArticleLinks}
                urlPrefix={CarouselSeoUrlPrefixEnum.Article}
                seoId={seoId}
              />
            </div>
          )}
          <Constructor zone={commonBottomZone} expertsInitial={expertsInitial} />
        </div>
      </div>
    </>
  );
};

export default Article;
